import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

class AuthGuardService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private cookies: SsrCookieService,
    private auth: AuthService
  ) { }

  // 0 = Logout; 1 = Login (PIN); 2 = FULL Login
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // if (isPlatformBrowser(this.platformId)) {
    //   console.log(next.routeConfig)
    //   const pathRoute = next.routeConfig?.path
    //   const isLogin = this.cookies.get('v5_il');
    //   const loginCode = isLogin ? parseInt(isLogin) : 0;
    //   if (pathRoute === "register" || pathRoute === "login"){
    //     loginCode > 0 ? this.router.navigate(['/']) : null;
    //     return loginCode > 0 ? false : true;
    //   } else if (pathRoute === "pin"){
    //     return loginCode == 1 ? true : false;
    //   } else {
    //     return loginCode > 1 ? true : false;
    //   }
    // }
    return new Promise((resolve) => {
      // console.log(next.routeConfig)
      // const pathRoute = next.routeConfig?.path
      // const isLogin = this.cookies.get('v5_il');
      // const loginCode = isLogin ? parseInt(isLogin) : 0;
      // if (pathRoute === "register" || pathRoute === "login") {
      //   loginCode > 0 ? this.router.navigate(['/']) : null;
      //   resolve(loginCode > 0 ? false : true)
      // } else if (pathRoute === "pin") {
      //   resolve(loginCode == 1 ? true : false)
      // } else {
      //   resolve(loginCode > 1 ? true : false)
      // }

      //Edit By Surya
      const pathRoute = next.routeConfig?.path;
      const isLogin = this.cookies.get('v5_il');
      const loginCode = isLogin ? parseInt(isLogin) : 0;

      console.log(isLogin);

      if (loginCode === 0) {
        if (pathRoute === "game/:id" || pathRoute === "pin" || pathRoute === 'saldo' || pathRoute === 'transaction' || pathRoute === 'statement' || pathRoute === 'profile' || pathRoute === 'deposit' || pathRoute === 'withdrawal') {
          if (isPlatformBrowser(this.platformId)) {
            this.router.navigate(['/login']);
          }
          resolve(false);
        } else {
          resolve(true);
        }
      } else if (loginCode === 1) {
        if (pathRoute === 'pin') {
          resolve(true);
        } else {
          if (isPlatformBrowser(this.platformId)) {
            this.router.navigate(['/pin']);
          }
          resolve(false);
        }
      } else {
        if (pathRoute === 'login' || pathRoute === 'register' || pathRoute === 'pin') {
          // if (isPlatformBrowser(this.platformId)) {
            this.router.navigate(['/']);
          // }
          if (isPlatformBrowser(this.platformId)) {
            // window.history.go(-3)
          }
          resolve(false);
        } else {
          resolve(true);
        }
      }
    })
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(AuthGuardService).canActivate(next, state);
}