import { Injectable, Inject, PLATFORM_ID, inject } from '@angular/core';
import { CommonModule, isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { DataService } from './data.service';
import { Meta, Title } from '@angular/platform-browser';
import { Domain } from '../schema/interface.global';
import { Router } from 'express';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
    private dataService: DataService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: Document,
    private meta: Meta,
    private title: Title
  ) { }

  setMetaWebsite(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let websiteData = this.dataService.websiteJSONData;
    if (
      !this.dataService.websiteJSONData ||
      !this.dataService.websiteJSONData['header']
    ) {
      return;
    }

    const header = this.dataService.websiteJSONData['header'];

    // var iframe = this.doc.createElement('iframe');
    // iframe.src = JSON.parse(this.dataService.websiteJSONData['script']['custom_code']['javascript']);
    // this.doc.write(this.dataService.websiteJSONData['script']['custom_code']['javascript'])
    // this.doc.body.appendChild(this.dataService.websiteJSONData['script']['custom_code']['javascript']);

    if (header['title']) {
      this.setTitle(header['title']);
    }

    this.meta.addTag({ name: 'categories', content: 'website' });
    this.meta.addTag({ name: 'theme-color', content: 'GREEN' });
    this.meta.addTag({ name: 'language', content: 'id_ID' });
    this.meta.addTag({ name: 'geo.region', content: 'indonesia' });
    this.meta.addTag({ name: 'geo.placename', content: 'jakarta' });

    if (header['meta']['description']) {
      this.meta.addTag({ name: 'description', content: header['meta']['description'] });
    }
    
    if (header['meta']['keywords']) {
      this.meta.addTag({ name: 'keywords', content: header['meta']['keywords'] });
    }
    
    this.meta.addTag({ property: 'og:locale', content: 'id_ID' });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    if (websiteData['domain']) {
      // console.log(websiteData['domain']);
      this.meta.addTag({ property: 'og:url', content: this.doc.location.origin });
    }
    if (header['title']) {
      // console.log(header['title'].replace(/ .*/, ''))
      let brand = header['title'].replace(/ .*/, '');
      this.meta.addTag({ property: 'og:site_name', content: brand });
      this.meta.addTag({ property: 'og:title', content: header['title'] });
    }
    this.meta.addTag({ property: 'og:image:alt', content: 'slot,demo slot,slot gacor,slot gacor hari ini,slot online,situs slot,slot terbaru' });
    
    if (header['meta']['description']) {
      this.meta.addTag({ property: 'og:description', content: header['meta']['description'] });
    }
    if (header['favicon']) {
      this.meta.addTag({ property: 'og:image:secure_url', content: header['favicon'] });
    }
    this.meta.addTag({ property: 'og:image:type', content: 'image/webp' });
    this.meta.addTag({ property: 'og:image:width', content: '300' });
    this.meta.addTag({ property: 'og:image:height', content: '300' });
    // if (header['meta']['owner']) {
    //   this.meta.addTag({ name: 'owner', content: header['meta']['owner'] });
    // }
    //hardcode -- remove soon
    // this.meta.addTag({ name: 'robots', content: 'noindex' });
    // this.meta.addTag({ name: 'googlebot', content: 'noindex' });
    //end hardcode -- remove soon

    this.addCanonicalAMP(websiteData['domain']);
    if(websiteData['domain']){
      this.updateCanonicalUrl(websiteData['header']['meta']['canonical'] as string, websiteData['domain']);
      this.addRobotNoIndex(websiteData['domain']);
    }

    if (header['favicon']) {
      this.setFavicon(header['favicon']);
    }

  }

  setTitle(title: string) {
    this.title.setTitle(title);
  }

  setFavicon(favicon: string) {
    // Remove existing favicon if exists
    let existingFavicon = this.doc.querySelector("link[rel='icon']");
    if (existingFavicon) {
      this.doc.head.removeChild(existingFavicon);
    }

    let newFavicon = this.doc.createElement('link');
    newFavicon.setAttribute('rel', 'icon');
    newFavicon.setAttribute('type', 'image/x-icon');
    newFavicon.setAttribute('href', favicon);

    this.doc.head.appendChild(newFavicon);
  }

  domainExtract(url: string): any {
    let dataDomain = url.match(
      /^(?<protocol>https?:\/\/)(?=(?<fqdn>[^:/]+))(?:(?<service>www|ww\d|cdn|ftp|st|mail|pop\d?|ns\d?|git)\.)?(?:(?<subdomain>[^:/]+)\.)*(?<domain>[^:/]+\.[a-z0-9]+)(?::(?<port>\d+))?(?<path>\/[^?]*)?(?:\?(?<query>[^#]*))?(?:#(?<hash>.*))?/i
    )?.groups;
    return dataDomain;
  }

  addCanonicalAMP(listDomain: any) {
    // console.log(this.doc.location.origin)
    // let domainAMP = listDomain.find((x: any) => x['amp'] === true);
    // console.log(domainAMP);
    // if (domainAMP) {
      const newCanonicalLink = this.doc.createElement(
        'link'
      ) as HTMLLinkElement;
      newCanonicalLink.rel = 'amphtml';
      newCanonicalLink.href = this.doc.location.origin+'/amp';
      this.doc.head.appendChild(newCanonicalLink);
    // }
  }

  updateCanonicalUrl(defaultCanonical: string, listDomain: Domain[]) {
    let url: string = defaultCanonical;
    // const currentProtocol = window.location.protocol;
    // const currentHostname = window.location.hostname;
    // console.log(this.doc.URL);
    // let currentUrl = `${currentProtocol}//${currentHostname}`;
    let currentUrl = this.doc.URL;
    const domainExtractResult = this.domainExtract(currentUrl);

    if (!domainExtractResult || !domainExtractResult.domain) {
      return;
    }

    let currentDomain = domainExtractResult.domain;
    let domainData = listDomain.find(
      (item: Domain) => this.domainExtract(item.domain).domain === currentDomain
    );
    if (domainData) {
      url = domainData.canonical;
    }
    // console.log(url)

    // const canonicalLink: HTMLLinkElement | null = this.doc.querySelector(
    //   'link[rel="canonical"]'
    // );
    // if (canonicalLink) {
    //   canonicalLink.href = url;
    // } else {
    //   const newCanonicalLink = this.doc.createElement(
    //     'link'
    //   ) as HTMLLinkElement;
    //   newCanonicalLink.rel = 'canonical';
    //   newCanonicalLink.href = url;
    //   this.doc.head.appendChild(newCanonicalLink);
    // }
    const newCanonicalLink = this.doc.createElement(
      'link'
    ) as HTMLLinkElement;
    newCanonicalLink.rel = 'canonical';
    newCanonicalLink.href = url;
    this.doc.head.appendChild(newCanonicalLink);
  }

  addRobotNoIndex(listDomain: Domain[]) {
    // const currentProtocol = window.location.protocol;
    // const currentHostname = window.location.hostname;
    // let currentUrl = `${currentProtocol}//${currentHostname}`;
    let currentUrl = this.doc.URL;
    // let currentUrl = 'https://testing.test.com';

    const domainExtractResult = this.domainExtract(currentUrl);

    if (!domainExtractResult || !domainExtractResult.domain) {
      return;
    }

    let currentDomain = domainExtractResult.domain;

    let domainData = listDomain.find(
      (item: Domain) =>
        this.domainExtract(item.domain)?.domain === currentDomain
    );

    if (!domainData) {
      return;
    }

    if (domainData.robot_no_index) {
      this.meta.addTag({ name: 'robots', content: 'noindex' });
      this.meta.addTag({ name: 'googlebot', content: 'noindex' });
      this.meta.addTag({ name: 'googlebot-news', content: 'noindex' });
    }
  }
}

// @Injectable({
//   providedIn: 'root'
// })

// class MetaWebsiteService {

//   constructor(
//     @Inject(PLATFORM_ID) private platformId: Object,
//     private router: Router,
//     private dataService: DataService,
//     private auth: AuthService,
//     private title: Title
//   ) { }

//   getMeta(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
//     this.title.setTitle('BAHAGIA');
//   }

// }

export const MetaWebsiteService: ResolveFn<any> = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(MetaService).setMetaWebsite(next, state);
}