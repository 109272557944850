<div class="navigation-title">
    <div class="back-button" (click)="backButton()">
        <fa-icon [icon]="faArrowLeft" [size]="'2xl'"></fa-icon>
    </div>
    <div class="title">DAFTAR</div>
</div>
<div class="container-fluid register-component pt-2 pb-5">
    <div class="main-register-mobile">
        <form (ngSubmit)="registerForm()">
            <div class="input-group border-bottom mb-3">
                <input type="text" class="form-control bg-transparent text-white text-uppercase border-right-0" placeholder="Nama Pengguna" maxlength="16"  pattern="[A-Z_0-9]*" name="register_login_id" [formControl]="usernameRegister" matInput type="text" [value]="" autocomplete="off" (ngModelChange)="usernameChange()" (input)="userNameRegisterOnInput()"/>
                <span class="input-group-text bg-transparent border-0">
                    <fa-icon [icon]="faUser" class="fa-1x"></fa-icon>
                </span>
            </div>
            @if(usernameRegister.invalid && (usernameRegister.dirty || usernameRegister.touched)){
                @if(usernameRegister.hasError('required')){
                    <div class="text-danger">
                        <span>Username wajib diisi.</span>
                    </div>
                }
                @if(usernameRegister.hasError('whitespace') || usernameRegister.hasError('pattern')){
                    <div class="text-danger">
                        <span>Karakter input tidak valid, hanya boleh mengisi huruf dan angka.</span>
                    </div>
                }
                @if(usernameRegister.hasError('existUserName')){
                    <div class="text-danger">
                        <span>Username sudah terdaftar</span>
                    </div>
                }
            }

            <div class="input-group border-bottom mb-3">
                <input type="tel" class="form-control bg-transparent text-white border-right-0"
                    placeholder="Nomor Handphone" [formControl]="phoneRegister" name="mobilePhone" aria-label="Nomor Handphone"
                    oninput="this.value = this.value.replace(/^(\+.*\+)|[^0-9\+]/g, '');"
                    autocomplete="off" required />
                <span class="input-group-text bg-transparent border-0">
                    <fa-icon [icon]="faPhone" class="fa-1x"></fa-icon>
                </span>
            </div>

            @if(phoneRegister.invalid && (phoneRegister.dirty || phoneRegister.touched)){
                @if(phoneRegister.hasError('required') || phoneRegister.hasError('minlength') || phoneRegister.hasError('pattern')){
                    <div class="text-danger">
                        <span>Karakter input tidak valid, hanya boleh mengisi angka.</span>
                    </div>
                }
            }


            <div class="input-group border-bottom mb-3">
                <input type="password" class="form-control bg-transparent text-white border-right-0"
                    [type]="isPasswordVisible ? 'text' : 'password'" placeholder="Password" [formControl]="passwordRegister" [value]=""
                    name="password" aria-label="Password" autocomplete="off" />
                <button class="btn transparentbtn-outline-secondary text-white border-0 outline-0" type="button"
                    (click)="isPasswordVisible = !isPasswordVisible">
                    <fa-icon [icon]="isPasswordVisible ? faEyeSlash : faEye" class="fa-1x"></fa-icon>
                </button>
            </div>

            @if(passwordRegister.invalid && (passwordRegister.dirty || passwordRegister.touched)){
                @if(passwordRegister.hasError('required') || passwordRegister.hasError('minlength') || passwordRegister.hasError('pattern')){
                    <div class="text-danger">
                        <span>Karakter input tidak valid, hanya boleh mengisi huruf dan angka.</span>
                    </div>
                }
            }

            <div class="input-group border-bottom mb-3">
                <input type="password" class="form-control bg-transparent text-white border-right-0"
                    [type]="isConfirmPasswordVisible ? 'text' : 'password'" placeholder="Confirm Password"
                    [formControl]="passwordConfirmRegister" name="confirmPassword" aria-label="Confirm Password" autocomplete="off" required />
                <button class="btn transparentbtn-outline-secondary text-white border-0 outline-0" type="button"
                    (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible">
                    <fa-icon [icon]="isConfirmPasswordVisible ? faEyeSlash : faEye" class="fa-1x"></fa-icon>
                </button>
            </div>

            @if(passwordConfirmRegister.invalid && (passwordConfirmRegister.dirty || passwordConfirmRegister.touched)){
                @if(passwordConfirmRegister.hasError('minlength') || passwordConfirmRegister.hasError('pattern')||passwordConfirmRegister.hasError('confirmedPassword')){
                    <div class="text-danger">
                        <span>Password tidak cocok</span>
                    </div>
                }
                @if(passwordConfirmRegister.hasError('required')){
                    <div class="text-danger">
                    <span>Input wajib diisi</span>
                    </div>
                }
            }

            @if(websiteJSONData.configuration.pin){
                <div class="input-group border-bottom mb-3">
                    <input type="password" class="form-control bg-transparent text-white border-right-0"
                        [type]="isPinVisible ? 'tel' : 'password'" placeholder="Create PIN" [formControl]="pinRegister"
                        aria-label="Create PIN" name="pin" oninput="this.value = this.value.replace(/[^0-9]/g,'');"
                        minlength="6" maxlength="6" autocomplete="off" required />
                    <button class="btn transparentbtn-outline-secondary text-white border-0 outline-0" type="button"
                        (click)="isPinVisible = !isPinVisible">
                        <fa-icon [icon]="isPinVisible ? faEyeSlash : faEye" class="fa-1x"></fa-icon>
                    </button>
                </div>
    
                @if(pinRegister.invalid && (pinRegister.dirty || pinRegister.touched)){
                    @if(pinRegister.hasError('required') || pinRegister.hasError('minlength') || pinRegister.hasError('maxlength')){
                        <div class="text-danger">
                            <span>PIN harus 6 digit</span>
                        </div>
                    }
                }
    
                <div class="input-group border-bottom mb-3">
                    <input type="password" class="form-control bg-transparent text-white border-right-0"
                        [type]="isConfirmPinVisible ? 'tel' : 'password'" placeholder="Confirm PIN" [formControl]="pinConfirmRegister"
                        name="confirmPin" aria-label="Confirm PIN" required
                        oninput="this.value = this.value.replace(/[^0-9]/g,'');" maxlength="6"
                        autocomplete="off" />
                    <button class="btn transparent btn-outline-secondary text-white border-0 outline-0" type="button"
                        (click)="isConfirmPinVisible = !isConfirmPinVisible">
                        <fa-icon [icon]="isConfirmPinVisible ? faEyeSlash : faEye" class="fa-1x"></fa-icon>
                    </button>
                </div>
    
                @if(pinConfirmRegister.invalid && (pinConfirmRegister.dirty || pinConfirmRegister.touched)){
                    @if(pinConfirmRegister.hasError('required') || pinRegister.hasError('minlength') || pinRegister.hasError('maxlength') || pinConfirmRegister.hasError('confirmedPin')){
                        <div class="text-danger">
                            <span>PIN tidak cocok</span>
                        </div>
                    }
                }
            }


            <div class="input-group input-group-dropdown border-bottom mb-3">
                <div ngbDropdown class="d-inline-block w-100">
                    <button type="button" class="btn btn-outline-primary w-100 btn-trigger" id="chooseBank" ngbDropdownToggle (click)="openFilterSelectCreateBank(currentSelectedCreateBank)">
                        <span class="img">
                            @if(currentSelectedCreateBank['images']){
                            <img [src]="currentSelectedCreateBank['images']['small']" [alt]="currentSelectedCreateBank['name']">
                            }
                        </span>
                        <span class="bank-name">{{currentSelectedCreateBank['name'] === "OTHER BANK" ? 'BANK LAINNYA': currentSelectedCreateBank['name']}}</span>
                    </button>
                    <div ngbDropdownMenu style="display: none;"></div>
                    <!-- <div ngbDropdownMenu class="w-100" aria-labelledby="chooseBank">
                        <div class="w-100 search-create-bank">
                            <input type="text" class="form-control" autocomplete="off" [(ngModel)]="inputSearchCreate"
                                (keyup)="onSearchcreateValue($event)" placeholder="Search Bank.." name="searchBankInput"
                                aria-label="Search Bank.." />
                            <button type="button" class="btn">
                                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                            </button>
                        </div>
                        @for (item of searchedCreateOptions; track item;) {
                        <button type="button" ngbDropdownItem (click)="selectedCreateBank(item)">
                            <span class="img">
                                <img [src]="item['images']['small']" [alt]="item['name']">
                            </span>
                            <span class="bank-name">{{item['name'] === "OTHER BANK" ? 'BANK LAINNYA': item['name']}}</span>
                            @if(currentSelectedCreateBank['_id'] === item['_id']){
                            <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                            }
                        </button>
                        }
                    </div> -->
                </div>
            </div>

            <div class="input-group border-bottom mb-3">
                <input type="tel" class="form-control bg-transparent text-white border-right-0" placeholder="Nomor Rekening"
                    [formControl]="accNumberRegister" name="account_number" aria-label="Nomor Rekening"
                    oninput="this.value = this.value.replace(/[^0-9]/g,'');" required autocomplete="off" />
                <span class="input-group-text bg-transparent border-0">
                    <fa-icon [icon]="faBank" class="fa-1x"></fa-icon>
                </span>
            </div>

            @if(accNumberRegister.invalid && (accNumberRegister.dirty || accNumberRegister.touched)){
                @if(accNumberRegister.hasError('required')){
                    <div class="text-danger">
                        <span>Input wajib diisi</span>
                    </div>
                }
            }

            <div class="input-group border-bottom mb-3">
                <input type="text" class="form-control bg-transparent text-white border-right-0" placeholder="Nama Rekening"
                    [formControl]="accNameRegister" pattern="[a-zA-Z0-9\s\.\,\'\&\x22\-\/]*" name="account_name" aria-label="Nama Rekening" autocomplete="off" required />
                <span class="input-group-text bg-transparent border-0">
                    <fa-icon [icon]="faBank" class="fa-1x"></fa-icon>
                </span>
            </div>

            @if(accNameRegister.invalid && (accNameRegister.dirty || accNameRegister.touched)){
                @if(accNameRegister.hasError('required')){
                    <div class="text-danger">
                        <span>Input wajib diisi</span>
                    </div>
                }
                @if(accNameRegister.hasError('pattern')){
                    <div class="text-danger">
                        <span>Format input salah</span>
                    </div>
                }
            }

            <div class="input-group border-bottom mb-3">
                <input type="text" class="form-control transparent bg-transparent text-white border-right-0" placeholder="REFERRAL" (input)="referralRegisterOnInput()" [formControl]="referralRegister" name="referral" aria-label="REFERRAL" [value]="" autocomplete="off" />
                <span class="input-group-text bg-transparent border-0">
                    <fa-icon [icon]="faUserGroup" class="fa-1x"></fa-icon>
                </span>
            </div>
            <button type="submit" class="fixed-bottom text-uppercase border-0 outline-0 text-white w-100 btn-sign-mobile">
                daftar
            </button>
        </form>
    </div>
</div>